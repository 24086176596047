<template>
  <div>
    <!-- <ToolbarCallCenter></ToolbarCallCenter> -->
    <footer class="footer">
      <div class="container info-gerais">
        <section class="subContainerSec">
          <div class="col-sm-12">
            <div class="col-xs-12 col-md-4 col-sm-4">
              <section>
                <div class="img-logo-box">
                  <img
                    v-if="logoUrl"
                    loading="lazy"
                    :src="logoUrl"
                    class="img-logo"
                  />
                  <img
                    v-else
                    loading="lazy"
                    src="~@/assets/img/logoDPC.svg"
                    class="img-logo"
                  />

                  <!-- <h5><strong>Quem Somos</strong></h5> -->
                  <p class="footer-text">
                    Empresa especializada na distribuição de cosméticos e
                    produtos de higiene pessoal.
                  </p>
                </div>
                <section class="section-social">
                  <h5>
                    <strong> Acompanhe nas redes sociais </strong>
                  </h5>
                  <a target="_blank" rel="noopener" :href="social.instagram">
                    <i
                      class="fa-brands fa-instagram social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.twitter">
                    <i
                      class="fa-brands fa-twitter social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.youtube">
                    <i
                      class="fa-brands fa-youtube social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.facebook">
                    <i
                      class="fa fa-facebook social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.linkedin">
                    <i
                      class="fa-brands fa-linkedin social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.tiktok">
                    <i
                      class="fa-brands fa-tiktok social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                </section>
              </section>
            </div>
            <div class="col-xs-12 col-md-4 col-sm-4">
              <section>
                <h5>
                  <strong>A {{ appNameShort }}</strong>
                </h5>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://institucional.dpcnet.com.br/"
                    >
                      <!-- <router-link to="/institucional" tag="a"> -->
                      <span>Institucional</span>
                    </a>
                    <!-- </router-link> -->
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://institucional.dpcnet.com.br/politica-de-privacidade"
                    >
                      <!-- <router-link to="/institucional" tag="a"> -->
                      <span>Política de Privacidade</span>
                    </a>
                    <!-- </router-link> -->
                  </li>
                  <!-- <li>
                  <router-link to="/atendimento" tag="a">
                    <strong>Onde estamos</strong>
                  </router-link>
                </li> -->
                  <li v-if="showTrabalheConosco">
                    <router-link to="/trabalhe-conosco" tag="a">
                      <span>Trabalhe conosco</span>
                    </router-link>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://institucional.dpcnet.com.br/faq/"
                    >
                      <span>Dúvidas frequentes</span>
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div class="col-xs-12 col-md-4 col-sm-4">
              <section>
                <h5>
                  <strong>Meus pedidos</strong>
                </h5>
                <ul>
                  <li>
                    <router-link to="/minha-conta/pedidos" tag="a">
                      <span>Acompanhe seus pedidos</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/minha-conta/titulos" tag="a">
                      <span>Segunda via de boletos</span>
                    </router-link>
                  </li>
                </ul>

                <h5>
                  <strong>Métodos de pagamento</strong>
                </h5>
                <ul>
                  <li>
                    <img
                      src="~@/assets/img/payment-methods.png"
                      alt=""
                      width="185"
                      loading="lazy"
                    />
                  </li>
                </ul>
              </section>
            </div>

            <!-- <div class="col-xs-12 col-md-3 col-sm-3">
  
            </div> -->
            <!-- <div class="col-xs-12 col-md-3 col-sm-3">
              <section>
                <h5>
                  <strong>Ajuda</strong>
                </h5>
                <ul>
                  <li>
                    <router-link to="/atendimento" tag="a">
                      <strong>Dúvidas frequentes</strong>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/atendimento" tag="a">
                      <strong>Fale conosco</strong>
                    </router-link>
                  </li>
                </ul>
              </section>
            </div> -->
          </div>

          <div
            class="col-sm-12 col-md-2 logo"
            style="border-top: 1px solid #ffffff1f; margin-top: 40px"
          >
            <section class="logo-section">
              <section>
                <h5>
                  <strong>Central de atendimento</strong>
                </h5>
                <ul>
                  <!-- <li>
                    <strong>Atendimento</strong>
                  </li> -->
                  <li>
                    <!-- <h4 class="fone"> -->
                    <a href="tel://3333290000">(33) 3329-0000</a>
                    <!-- </h4> -->
                  </li>
                </ul>

                <div class="text-block" style="padding-top: 15px">
                  <div v-if="getSacHrDiaUtilInicio && getSacHrDiaUtilFim">
                    <strong>Horário de funcionamento:</strong>
                    <br />Segunda a sexta-feira
                    <br />
                    {{ getSacHrDiaUtilInicio }} às {{ getSacHrDiaUtilFim }}
                  </div>
                  <div v-if="getSacSabadoInicio && getSacSabadoFim">
                    Sábados
                    <br />
                    {{ getSacSabadoInicio }} às {{ getSacSabadoFim }}
                  </div>
                  <div v-if="getSacDomingoInicio && getSacDomingoFim">
                    Domingos
                    <br />
                    {{ getSacDomingoInicio }} às {{ getSacDomingoFim }}
                  </div>
                </div>
                <br />
              </section>

              <!-- <div class="logo-section-sac-cc"> -->
              <section>
                <h5>
                  <strong>Call Center</strong>
                </h5>
                <ul>
                  <!-- <li>
                    <strong>Call Center</strong>
                  </li> -->
                  <li>
                    <!-- <h4 class="fone"> -->
                    <a href="tel://08002850005">0800-285-0005</a>
                    <!-- </h4> -->
                  </li>
                  <li>
                    <!-- <h4 class="fone"> -->
                    <a href="tel://08005903000">0800-590-3000</a>
                    <!-- </h4> -->
                  </li>
                  <li>
                    <!-- <h4 class="fone"> -->
                    <a href="tel://3333290014">(33) 3329-0014</a>
                    <!-- </h4> -->
                  </li>
                </ul>
                <br />
                <!-- <div class="text-block">
                  <div v-if="getTelHrDiaUtilInicio && getTelHrDiaUtilFim">
                    <strong>Horário de funcionamento:</strong>
                    <br />Segunda a sexta-feira
                    <br />
                    {{ getTelHrDiaUtilInicio }} às {{ getTelHrDiaUtilFim }}
                  </div>
                  <div v-if="getTelSabadoInicio && getTelSabadoFim">
                    Sábados
                    <br />
                    {{ getTelSabadoInicio }} às {{ getTelSabadoFim }}
                  </div>
                  <div v-if="getTelDomingoInicio && getTelDomingoFim">
                    Domingos
                    <br />
                    {{ getTelDomingoInicio }} às {{ getTelDomingoFim }}
                  </div>
                </div> -->
              </section>

              <section>
                <h5>
                  <strong>SAC</strong>
                </h5>
                <ul>
                  <li>
                    <!-- <h4 class="fone"> -->
                    <a href="tel://08002830000">0800-283-0000</a>
                    <!-- </h4> -->
                  </li>
                  <li>
                    <!-- <h4 class="fone"> -->
                    <a href="tel://3333290046">(33) 3329-0046</a>
                    <!-- </h4> -->
                  </li>
                </ul>
                <br />
              </section>
              <!-- </div> -->

              <div class="right-side">
                <div style="display: flex; flex-direction: column">
                  <a
                    href="https://apps.apple.com/app/apple-store/id6454296689?pt=126474261&ct=website%20footer&mt=8"
                    style="
                      display: inline-block;
                      overflow: hidden;
                      width: 115px;
                      height: auto;
                    "
                    target="_blank"
                    ><img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=120x83&amp;releaseDate=1691712000"
                      alt="Download on the App Store"
                      style="width: 115px; height: auto"
                  /></a>

                  <a
                    style="
                      display: inline-block;
                      overflow: hidden;
                      width: 115px;
                      height: auto;
                    "
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.aglets.dpcnet"
                    ><img
                      alt="Disponível no Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
                      style="width: 115px; height: auto"
                  /></a>
                </div>

                <div class="sb-seal">
                  <div id="armored_website">
                    <param id="aw_preload" value="true" />
                    <param id="aw_use_cdn" value="true" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
      <div class="container footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-12 dados-empresa">
              <p class="text-center">
                © Copyright {{ appNameShort }} - Todos os direitos reservados.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import * as global from "@/app/global";
import ToolbarCallCenter from "@/components/toolbar-callcenter/ToolbarCallCenter.vue";

export default {
  name: "Footer",
  components: {
    ToolbarCallCenter,
  },
  data() {
    return {
      social: {
        instagram: "https://instagram.com/dpcdistribuidor",
        facebook: "https://facebook.com/dpcdistribuidor",
        linkedin: "https://linkedin.com/company/dpc-distribuidor-atacadista-sa",
        tiktok: "https://tiktok.com/@dpcdistribuidor",
        youtube: "https://www.youtube.com/channel/UC0nV03Lj_o1PBvdCojAfUHQ",
        twitter: "https://twitter.com/dpcatacadista",
      },
      logoUrl: null,
      appNameShort: process.env.VUE_APP_NAME_SHORT,
      showTrabalheConosco:
        process.env.VUE_APP_STORE_SHOW_TABALHE_CONOSCO == "true",
    };
  },
  computed: {
    getTelTelevenda() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_televenda
      );
    },
    getTelSac() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_sac
      );
    },
    getFaleConosco() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.fale_conosco
      );
    },
    getTelHrDiaUtilInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_dia_util_inicio
      );
    },
    getTelHrDiaUtilFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_dia_util_fim
      );
    },
    getTelSabadoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_sabado_inicio
      );
    },
    getTelSabadoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_sabado_fim
      );
    },
    getTelDomingoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_domingo_inicio
      );
    },
    getTelDomingoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_domingo_fim
      );
    },
    getSacHrDiaUtilInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_dia_util_inicio
      );
    },
    getSacHrDiaUtilFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_dia_util_fim
      );
    },
    getSacSabadoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_sabado_inicio
      );
    },
    getSacSabadoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_sabado_fim
      );
    },
    getSacDomingoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_domingo_inicio
      );
    },
    getSacDomingoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_domingo_fim
      );
    },
  },
  mounted() {
    const script = document.createElement("script");
    // script.onload = () => {
    // }
    script.src = "//cdn.siteblindado.com/aw.js";
    document.head.appendChild(script);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.img-logo-box {
  justify-self: flex-start;
  width: 60%;
}
.right-side {
  width: 32%;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
// .section-social {
//   width: 25%;
// }
.subContainerSec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.logo-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.sb-seal {
  background-color: white;
  border-radius: 6.5px;
  padding: 5px;
  width: fit-content;
}
.footer {
  bottom: 0;
  width: 100%;
  padding-top: 35px;
  background-color: var(--brand-secondary, $brand-secondary);
  color: #fff;
  font-size: 0.95em;
  h5 {
    font-weight: 600;
    color: var(--brand-accent, $brand-accent);
    font-size: 1.1em;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .social-icon {
    background-color: var(--brand-primary, $brand-primary);
    border: 1px solid var(--brand-primary, $brand-primary);
    color: white;
    padding: 0;
    border-radius: 50px;
    font-size: 1.15em;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    margin: 0;

    &:hover {
      opacity: 0.8;
      color: var(--brand-accent, $brand-accent);
      // color: #fff;
      // border: 01px solid #fff;
    }
    &:before {
      top: 2px;
      position: relative;
    }
  }
}
.footer-bottom {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 1em;
}
.col-sm-12.logo {
  text-align: center;
  padding-top: 25px;
}
.logo {
  display: flex;
  width: 100%;
}
.img-logo {
  width: 198px;
  margin-left: -10px;

  display: flex;
}
.footer-text {
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 0.97em;
}
.fone {
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 1.3em !important;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    a {
      color: #fff;

      &:hover {
        color: var(--brand-accent, $brand-accent);
      }

      span {
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
  .fa-angle-right {
    //font-size: 10px;
    margin-right: 4px;
    color: $brand-info;
  }
}
.dados-empresa {
  padding-left: 0;
}
@media (max-width: 768px) {
  .right-side {
    width: initial !important;
    margin-bottom: 25px;
  }
  .logo-section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .section-social {
    width: 100%;
    margin-top: 10px;
    justify-items: center;
  }
  .img-logo-box {
    width: 50%;
  }
  .footer {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .img-logo-box {
    width: 100%;
    place-items: center;
  }

  .subContainerSec,
  .logo-section {
    text-align: center;
    align-items: center;
  }

  // .logo-section-sac-cc {
  //   display: flex;
  //   flex-direction: row-reverse;
  //   width: 100%;
  //   align-content: space-between;
  //   gap: 35px;
  //   flex-wrap: wrap;
  //   justify-content: center;
  // }

  .right-side {
    align-items: center;

    div:first-child {
      flex-direction: row !important;
      align-items: center;
      gap: 15px;
    }
  }
}

@media (max-width: 991px) {
  .footer {
    padding-top: 20px;
  }
  .footer-bottom {
    border: none;
    padding-top: 0px;
    p {
      text-align: center;
    }
  }
}
</style>
