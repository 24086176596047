<template>
  <div class="accordion-grp-atv">
    <div class="content-grp">
      <div class="accordion-grp-atv-rules">
        <h4>
          <b>{{
            `Combo ${index} -  ${comboAtivacao.descricao
              .toLowerCase()
              .replace("combo", "")}`
          }}</b>
          <span v-if="comboAtivacao.disabled" class="text-warning">
            (indisponível)</span
          >
        </h4>

        <span v-if="comboAtivacao.disabled"
          >O combo <b>{{ comboAtivacao.descricao }}</b> não poderá ser ativado,
          pois um ou mais produtos necessários para a ativação do desconto estão
          indisponíveis no momento.</span
        >

        <TimelineItem
          v-if="!comboAtivacao.disabled"
          :index="index"
          :grupo-ativacao="comboAtivacao"
          :campanha-id="campanhaId"
        />
      </div>
    </div>
    <SliderCampanha
      v-if="!loading && !comboAtivacao.disabled"
      :produtos="produtos"
    ></SliderCampanha>
  </div>
</template>

<script>
import TimelineItem from "./components/Timeline/TimelineItem.vue";
import { mascaraValor } from "@/app/global.js";
import CardValorMinimo from "./CardValorMinimo.vue";
import SliderCampanha from "@/components/combo-campanhas/SliderCampanha";

export default {
  name: "ComboAtivacaoCard",
  components: {
    SliderCampanha,
    CardValorMinimo,
    TimelineItem,
  },
  props: {
    campanhaId: "",
    comboAtivacao: {},
    index: 0,
  },
  data() {
    return {
      produtos: [],
      loading: true,
    };
  },

  created() {
    this.getProducts();
  },
  methods: {
    mascaraValor,
    trataProduto(produto, qtdMinimaAtivacao = NULL) {
      if (!produto) return {};
      const produtoTratado = {};
      produtoTratado.descricao = produto.descricao;
      produtoTratado.descricaodetalhada = produto.descricaodetalhada;
      produtoTratado.dun14 = produto.dun14;
      produtoTratado.ean = produto.ean;
      produtoTratado.embqtddun14 = produto.embqtddun14;
      produtoTratado.embtpdun14 = produto.embtpdun14;
      produtoTratado.embqtdean = produto.embqtdean;
      produtoTratado.fornecedor_id = produto.fornecedor_id;
      produtoTratado.hascasadinha = produto.hascasadinha;
      produtoTratado.hasprodbloq = produto.hasprodbloq;
      produtoTratado.hascondbloq = produto.hascondbloq;
      produtoTratado.cod_produto = produto.id;
      produtoTratado.src = produto.src;
      produtoTratado.possuiestoque = produto.possuiestoque;
      produtoTratado.integracao_id = produto.integracao_id;
      produtoTratado.codigo_exibicao = produto.codigo_exibicao;
      produtoTratado.preco_all_tabs = produto.preco_all_tabs;
      produtoTratado.vlracresc = produto.vlracresc;
      produtoTratado.vlrdesconto = produto.vlrdesc;
      produtoTratado.vlrstdesonerado = produto.vlrstdesonerado;
      produtoTratado.embeantext = produto.embeantext;
      produtoTratado.valor = produto.vlrproduto;
      produtoTratado.vlrst = produto.vlrst;
      produtoTratado.vlrbruto =
        produto.vlrproduto +
        produto.vlrst -
        produto.vlrdesc -
        produto.vlrstdesonerado;

      if (this.$store.getters.carrinhoAtual.length) {
        const itemAdicionado = this.$store.getters.carrinhoAtual.find(
          (p) => p.cod_produto === produto.id
        );

        produtoTratado.qtd = itemAdicionado ? itemAdicionado.qtd : 0;
      } else {
        produtoTratado.qtd = 0;
      }
      produtoTratado.qtdMinimaAtivacao = qtdMinimaAtivacao;
      return produtoTratado;
    },
    async getProducts() {
      let produtosAux = this.comboAtivacao.produtos_ativacao.map(
        async (produtoAtv) => {
          const productAux = await this.trataProduto(
            produtoAtv.produto,
            produtoAtv.qtd_unidades_min
          );
          return productAux;
        }
      );
      produtosAux = await Promise.all(produtosAux);

      produtosAux.sort((item) => {
        if (item.qtdMinimaAtivacao > 0) {
          return -1;
        }
        return 1;
      });

      this.produtos = produtosAux;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-grp-atv {
  overflow: hidden;
  flex-direction: column;
  // margin-bottom: 15px;
  // margin-top: 15px;
  .content-grp {
    border-radius: 6px;
    // margin-top: 35px;
    // margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      letter-spacing: 0.5;
      font-weight: bold;
      color: #1ba256;
    }
  }
}
.accordion-grp-atv-rules {
  // margin-top: 35px;
  // margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  .description {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    color: #303932;
  }
  .rule {
    font-size: 14px;
    font-weight: normal;
    color: #979797;
  }
}
</style>
