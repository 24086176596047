<template>
  <div
    id="item-produto"
    class="item-produto"
    :class="[prodClass, defaultClass]"
  >
    <div
      class="produto-content col-lg-12 col-md-12 col-sm-12 col-xs-12 rounded-default"
    >
      <div class="produto-content__image">
        <div class="flex flex-row justify-end">
          <div
            v-if="quantidade > 0 && dadosPedido"
            class="item-tag-info"
            @click="callOpenFloatingCart(true)"
          >
            <i class="fa fa-truck"></i>
            <span>Item no caminhão</span>
          </div>

          <div v-if="item.vlrdesconto" class="discountBadge">
            <arrow-down-icon size="1x" class="custom-class" />
            <span>{{ item.displayDescontoPct }}</span>
          </div>
        </div>

        <router-link
          v-if="item.descricao"
          tag="a"
          :to="{
            name: 'produtoRota',
            params: {
              codigo: item.integracao_id,
              id: item.cod_produto,
              filtroId: $route.query.filtroId,
            },
          }"
          :title="item.descricao"
          style="flex: 1 1 auto; display: flex"
          @click.native="closeOverlay"
        >
          <div
            :class="
              item.descricao.length > 65
                ? 'imgContainerProduto containerImg150'
                : 'imgContainerProduto'
            "
          >
            <div
              class="imagem"
              :style="hasVariations ? 'padding-bottom: 50px!important' : null"
            >
              <!-- <div class="img-qr" v-html="DATAMatrix('5453534').outerHTML"></div> -->

              <!-- <router-link
          tag="a"
          v-on:click.native="closeOverlay"
          :to="{ name: 'produtoRota', params: { codigo: item.integracao_id } }"
          >-->

              <img
                v-if="srcPreview"
                class="img img-responsive"
                :src="srcPreview"
                onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
                :alt="item.descricao"
                loading="lazy"
              />

              <ImageItem
                v-else
                custom-class="img img-responsive"
                :source="item.src"
                :description="item.descricao"
              />

              <!-- </router-link> -->
            </div>
          </div>
          <div class="img-thumb">
            <i
              class="fa fa-camera"
              aria-hidden="true"
              @mouseover="pop_active = !pop_active"
              @mouseout="pop_active = !pop_active"
            ></i>
            <Popover :active="pop_active">
              <img
                class="img-responsive img-pop"
                :src="item.src"
                oneerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
                alt
                loading="lazy"
              />
            </Popover>
          </div>
        </router-link>

        <!-- todo: transformar em componente e reutilizar na tela de detalhes.  -->
        <product-variant-list
          v-if="hasVariations"
          ref="variants"
          :product-variant="item.grupo"
          :is-preview="true"
          :current-product-id="item.integracao_id"
          style="margin-top: -50px; z-index: 2"
          @imagePreview="srcPreview = $event"
          @product-selected="variantSelectedProductId = $event"
        ></product-variant-list>
      </div>

      <div class="informacoes">
        <div v-if="item.ads" class="sponsored-label">
          <span class="sponsored-label__text">Patrocinado</span>
          <i class="mdi mdi-information-outline"></i>
        </div>

        <div
          v-if="item.embeantext"
          class="codigo headInfoContent"
          style="width: 100%"
        >
          {{ item.embeantext }}
          <Alert
            v-if="
              item.qtdMinimaAtivacao > 0 && quantidade < item.qtdMinimaAtivacao
            "
            :type-alert="'alertDanger'"
            :description="getQtdMinimaAtivacaoCampanha()"
          />
        </div>
        <div class="titulo-produto">
          <router-link
            tag="a"
            :to="{
              name: 'produtoRota',
              params: {
                codigo: item.integracao_id,
                id: item.cod_produto,
                filtroId: $route.query.filtroId,
              },
            }"
            :title="item.descricao"
            @click.native="closeOverlay"
          >
            <!-- <span
              v-if="item.grupo && item.grupo.titulo"
              class="titulo"
              style="-webkit-box-orient: vertical"
            >
              {{ toTitleCase(item.grupo.titulo) }}</span
            > -->
            <span class="titulo" style="-webkit-box-orient: vertical">
              {{ toTitleCase(item.descricao) }}</span
            >

            <div class="descricao"></div>
          </router-link>
          <div
            v-if="isAuthenticated && dadosPedido && item.hascasadinha"
            class="casadinha"
          >
            <a @click="handleCasadinha">
              <i class="fa fa-gift"></i>
            </a>
          </div>
        </div>

        <div class="container-footer">
          <div
            v-if="isAuthenticated && dadosPedido && isAvailable"
            class="produto-price-container"
          >
            <div class="price-item">
              <strike
                v-if="item.vlrdesconto > 0 || item.vlrstdesonerado > 0"
                class="price-old"
              >
                <span
                  >De R$ {{ mascaraValor(item.valor + item.vlrst) }} por</span
                >
              </strike>

              <div class="price-default">
                <span class="price-value"
                  >R$ {{ mascaraValor(item.vlrbruto) }}</span
                >
              </div>
            </div>
            <ContainerPrice
              v-if="isAuthenticated && dadosPedido && isAvailable"
              :st="mascaraValor(item.vlrst / item.embqtdean, 4)"
              :produto="
                mascaraValor((item.vlrbruto - item.vlrst) / item.embqtdean, 4)
              "
              :desconto="mascaraValor(item.vlrdesconto / item.embqtdean, 4)"
              :stdesonerado="
                mascaraValor(item.vlrstdesonerado / item.embqtdean, 4)
              "
              :embalagem="embText()"
              :embvenda="item.embeantext"
              :total-unitario="mascaraValor(item.vlrbruto / item.embqtdean, 4)"
            ></ContainerPrice>
          </div>
          <div class="footer-item flex flex-col justify-between">
            <a
              v-if="
                isAuthenticated &&
                dadosPedido &&
                isAvailable &&
                productsWithCampanha[item.integracao_id] &&
                getCurrentRouter()
              "
              class="promocao flex flex-row items-center justify-between"
              @click="
                handlePromocaoCampanha(
                  productsWithCampanha[item.integracao_id].paramsUrl
                )
              "
            >
              <figure aria-hidden="true" class="icon-container">
                <svg
                  class="promo-icon"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#028d45"
                    d="M2.04388 3.07091L3.77432 11.47H13.8226L15.265 4.27064H14.1025L12.8392 10.27H4.75229L3.76349 5.47064L3.51626 4.27064L3.02185 1.87091H0.734863V3.07091H2.04388ZM4.93086 14.4697C5.59361 14.4697 6.13086 13.9324 6.13086 13.2697C6.13086 12.607 5.59361 12.0697 4.93086 12.0697C4.26812 12.0697 3.73086 12.607 3.73086 13.2697C3.73086 13.9324 4.26812 14.4697 4.93086 14.4697ZM12.7289 14.4697C13.3916 14.4697 13.9289 13.9324 13.9289 13.2697C13.9289 12.607 13.3916 12.0697 12.7289 12.0697C12.0661 12.0697 11.5289 12.607 11.5289 13.2697C11.5289 13.9324 12.0661 14.4697 12.7289 14.4697ZM6.53383 8.65687L12.1338 3.05687L11.2853 2.20834L5.6853 7.80834L6.53383 8.65687ZM6.73378 4.25697C7.28607 4.25697 7.73378 3.80925 7.73378 3.25697C7.73378 2.70468 7.28607 2.25697 6.73378 2.25697C6.1815 2.25697 5.73378 2.70468 5.73378 3.25697C5.73378 3.80925 6.1815 4.25697 6.73378 4.25697ZM11.9337 7.47023C11.9337 8.02251 11.486 8.47023 10.9337 8.47023C10.3814 8.47023 9.93373 8.02251 9.93373 7.47023C9.93373 6.91794 10.3814 6.47023 10.9337 6.47023C11.486 6.47023 11.9337 6.91794 11.9337 7.47023Z"
                  ></path>
                </svg>
              </figure>
              <div>
                <p
                  v-if="
                    productsWithCampanha[item.integracao_id].displayDiscount &&
                    productsWithCampanha[item.integracao_id].maxDesconto > 0 &&
                    [0, 1].includes(
                      productsWithCampanha[item.integracao_id]?.tpDesconto
                    )
                  "
                  class="text-promotion"
                >
                  Até
                  {{ productsWithCampanha[item.integracao_id].displayDiscount }}
                  OFF levando mais produtos participantes de uma promoção
                </p>
                <p
                  v-else-if="
                    productsWithCampanha[item.integracao_id].tpDesconto == 0
                  "
                  class="text-promotion"
                >
                  Até
                  {{
                    `R$${parseFloat(
                      productsWithCampanha[item.integracao_id].maxDesconto
                    )} `
                  }}
                  OFF levando mais produtos participantes de uma promoção
                </p>
                <p
                  v-else-if="
                    productsWithCampanha[item.integracao_id].tpDesconto == 1
                  "
                  class="text-promotion"
                >
                  Até
                  {{
                    `${parseFloat(
                      productsWithCampanha[item.integracao_id].maxDesconto
                    )}% `
                  }}
                  OFF levando mais produtos participantes de uma promoção
                </p>
                <p
                  v-else-if="
                    productsWithCampanha[item.integracao_id].tpDesconto == 2
                  "
                  class="text-promotion"
                >
                  Ganhe desconto levando mais produtos participantes de uma
                  promoção
                </p>
                <p
                  v-else-if="
                    productsWithCampanha[item.integracao_id].tpDesconto == 3
                  "
                  class="text-promotion"
                >
                  Ganhe desconto levando mais produtos participantes de uma
                  promoção
                </p>
              </div>
            </a>
            <div v-if="isAuthenticated && dadosPedido" class="qtd-container">
              <template v-if="isAvailable">
                <!-- <Alert
                  v-if="
                    this.item.qtdMinimaAtivacao > 0 &&
                    this.quantidade < this.item.qtdMinimaAtivacao
                  "
                  :typeAlert="'alertDanger'"
                  :description="getQtdMinimaAtivacaoCampanha()"
                ></Alert> -->
              </template>

              <div v-else-if="item.hascondbloq">
                <Alert
                  :description="'Produto indisponivel para os parametros selecionados'"
                ></Alert>
                <router-link
                  style="
                    text-align: center;
                    font-size: 89%;
                    color: #001f60;
                    cursor: pointer;
                  "
                  tag="div"
                  :to="{ name: 'catalogoRota' }"
                  @click.native="closeOverlay"
                >
                  <span>Clique aqui para ver os itens disponiveis</span>
                </router-link>
              </div>
              <Alert
                v-else-if="!loadingProducts"
                :description="'Indisponível'"
                style="padding: 7px; font-size: 14px"
              ></Alert>
            </div>
          </div>
        </div>
        <div class="specsProduto">
          <template v-if="showItemQtyBtn">
            <div v-if="(!isAuthenticated || isAvailable) && hasVariations">
              <router-link
                class="btn-addItem"
                tag="button"
                :to="{
                  name: 'produtoRota',
                  params: {
                    codigo: item.integracao_id,
                    id: item.cod_produto,
                    filtroId: $route.query.filtroId,
                  },
                }"
              >
                <i class="fa fa-plus"></i>
                Opções disponíveis
              </router-link>
            </div>
            <item-qty-btn
              v-else-if="isAvailable && isAuthenticated && dadosPedido"
              class="qtd-subContainer"
              :item="item"
              :round-type="true"
            ></item-qty-btn>

            <template v-else-if="dadosPedido == false || !isAuthenticated">
              <div class="qtd-subContainer">
                <button
                  title="Adicionar ao carrinho"
                  class="btn-buy"
                  @click="handleBuyClick"
                >
                  Ver Preço
                </button>
              </div>
            </template>
          </template>

          <div v-if="!hasVariations">
            <div class="codigo">
              Cod.: {{ item.codigo_exibicao ?? item.integracao_id
              }}<template v-if="userId && false"> -{{ userId }} </template>
            </div>
            <div class="codigo">EAN: {{ item.ean }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDownIcon from "vue-feather-icons/icons/ArrowDownIcon";
// import inViewport from "vue-in-viewport-mixin";
import Popover from "@/components/popover/Popover";
import bus from "@/utils/events/bus";
import { mascaraValor, toTitleCase, DATAMatrix } from "@/app/global";

import ItemQtyBtn from "@/components/produtos/ItemQtyBtn";
import ContainerPrice from "@/components/produtos/components/ContainerPrice";
import Alert from "@/components/alert/Alert";
import ProductVariantList from "./components/product-variant/ProductVariantList.vue";

import ImageItem from "@/components/image/ImageItem";

export default {
  name: "ItemProduto",
  components: {
    Popover,
    ItemQtyBtn,
    ContainerPrice,
    Alert,
    ArrowDownIcon,
    ProductVariantList,
    ImageItem,
  },
  // mixins: [inViewport],
  props: {
    loadingProducts: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
    },
    visualizacao: {
      type: String,
      default: "bloco-1",
    },
    defaultClass: {
      type: String,
      default: "",
    },
    // Sempre utilizar o modo passado no parametro, nunca irá obedecer a ordem selecionada pelo usuário. Útil para coisas como slider.
    useDefaultViewType: {
      default: false,
      type: Boolean,
    },
    showItemQtyBtn: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      productSeen: false,
      srcPreview: null,
      prodClass: {
        "col-lg-2": true,
        "col-md-3": true,
        "col-sm-3": true,
        "col-xs-3": true,
      },

      // dadosPedido: false,
      // isAuthenticated: false,
      pop_active: false,
      variantSelectedProductId: null,
    };
  },
  computed: {
    hasVariations() {
      return (
        this.item.grupo && Object.keys(this.item.grupo).length > 0
        // &&
        // this.variantSelectedProductId != this.item.id &&
        // this.$refs.variants &&
        // this.$refs.variants.numberOfVariants <= 1
      );
    },
    descontoPct() {
      return Math.round(
        ((this.item.vlrdesconto + this.item.vlrstdesonerado) * 100) /
          this.item.valor
      );
    },
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    userId() {
      const user = JSON.parse(localStorage.getItem("loggedUser") || "{}");
      return user.usuario_id || "";
    },
    dadosPedido() {
      const { dadosPedido } = this.$store.getters;
      if (Object.keys(dadosPedido).length > 0) {
        return dadosPedido;
      }
      return false;
    },
    productsWithCampanha() {
      return this.$store.getters.productsWithCampanha;
    },
    isAvailable: {
      get() {
        return (
          this.item.possuiestoque &&
          this.item.valor > 0 &&
          this.item.hasprodbloq == 0 &&
          this.item.hascondbloq == 0
        );
      },
    },
    // todo: verificar se ainda precisa retornar quantidade, ou somente ver se o item está no carrinho.
    quantidade: {
      get() {
        const item = this.$store.getters.carrinhoAtual;
        const produto = item.find(
          (i) => i.cod_produto == this.item.cod_produto
        );
        if (produto) {
          return produto.qtd;
        }
        return 0;
      },
      set(value) {
        this.quantidadeHelper = value;
        this.$emit("alterarQtd", value);
      },
    },
  },
  watch: {
    "$store.getters.modoVisualizacao": function () {
      this.refreshVisualizacao();
    },
    // "inViewport.now": function (visible) {
    //   if (visible && !this.productSeen) {
    //     this.setStatsViewed();
    //   }
    // },
  },
  created() {
    this.refreshVisualizacao();

    // this.dadosPedido =
    //   Object.keys(this.$store.getters.dadosPedido).length !== 0
    //     ? this.$store.getters.dadosPedido
    //     : false;
  },
  mounted() {
    // console.log(this.item);

    /**
     * Pega informações do pedido se existirem no vuex
     */

    // Object.keys(this.$store.getters.dadosPedido).length !== 0
    //   ? (this.dadosPedido = this.$store.getters.dadosPedido)
    //   : false;

    /**
     * Adiciona watch na alteração do estado dos dados do pedido
     */

    // this.$store.subscribe((mutation, state) => {
    //   switch (mutation.type) {
    //     case "setDadosPedido":
    //       this.dadosPedido = state.dadosPedido;
    //   }
    // });

    bus.$on("shouldRemoveCartItem", (e) => {
      this.item.cod_produto === e
        ? (this.quantidade = 0)
        : (this.quantidade = this.quantidade);
    });

    /**
     * Valida se usuário está logado
     */

    // if (!Account.isAuthenticated()) {
    //   this.isAuthenticated = false;
    // } else {
    //   this.isAuthenticated = true;
    // }
  },
  methods: {
    // setStatsViewed() {
    //   this.productSeen = true;

    //   if (this.$route.query.filtroId) {
    //     try {
    //       dpcAxios
    //         .connection()
    //         .post(
    //           `${process.env.VUE_APP_APIB2B}/v1/navigation/search/filter/${this.$route.query.filtroId}/event`,
    //           {
    //             event_type: "view-catalog",
    //             event_data: {
    //               produto_id: this.item.cod_produto,
    //             },
    //           }
    //         );
    //     } catch (error) {
    //       // console.error("", error);
    //     }
    //   }
    // },
    getCurrentRouter() {
      return this.$router.history.current.name == "catalogoRota";
    },
    DATAMatrix,
    toTitleCase,
    callOpenFloatingCart(e) {
      bus.$emit("showFloatingCart", e);
    },
    handlePromocaoCampanha(paramsUrl) {
      this.$router.push({
        path: "/campanhas",
        query: {
          buscaCampanha: paramsUrl,
        },
      });
    },
    embText() {
      let text = "";
      switch (this.item.embtpdun14) {
        case "UN":
          text = `${this.item.embqtddun14} unidade`;
          if (this.item.embqtddun14 > 1) {
            text += "s";
          }
          return text;
          break;
        case "PC":
          return (text = `Pacote com ${this.item.embqtddun14}`);
          break;
        case "CX":
          return (text = `Caixa com ${this.item.embqtddun14}`);
          break;
        case "FD":
          return (text = `Fardo com ${this.item.embqtddun14}`);
        case "DP":
          return (text = `Display com ${this.item.embqtddun14}`);
          break;
        default:
          return (text = `${this.item.embtpdun14} com ${this.item.embqtddun14}`);
          break;
      }
    },
    mascaraValor,
    /*
     * Função: Valida a digitação de apenas números na quantidade do item.
     * Chamada por: Evento 'KeyPress' do input de itens.
     * */

    handleCasadinha(e) {
      bus.$emit("openModalCasadinha", this.item);
    },

    isNumber(evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    /**
     * Função chamada no click do botão de adicionar ou remover item da quantidade do carrinho
     */
    handleButtonClick(type) {
      let { qtd } = this.item;

      if (type == "minus") {
        if (qtd === 0) {
          this.$emit("alterarQtd", 0);
        } else {
          qtd -= 1;
          this.$emit("alterarQtd", qtd);
        }
      } else if (type == "add") {
        if (qtd === "") {
          this.$emit("alterarQtd", 1);
        } else {
          qtd += 1;
          this.$emit("alterarQtd", qtd);
        }
      }

      // Atualiza o store do carrinho
      this.shouldStoreUpdate(qtd);
    },

    /**
     * Função chamada para atualizar o store com novas informações do produto
     */

    shouldStoreUpdate(qtd) {
      if (qtd === "") {
        qtd = 0;
        this.$emit("alterarQtd", qtd);
      }

      const produto = this.item;

      produto.pedido_id = this.dadosPedido.pedidoId;
      // console.log(produto);

      // return;
      this.$store.dispatch("addItemCarrinhoAtual", produto);
    },
    /**
     * Função responsável por abrir modal para selecionar os parametros de compra
     */
    handleBuyClick() {
      if (!this.isAuthenticated) {
        this.$route.query.isLoginRequired = true;
        this.$router.push({
          name: "login",
        });
        return;
      }
      bus.$emit("openModalParametros", true);
    },
    refreshVisualizacao() {
      let tipo = this.$store.getters.modoVisualizacao;

      if (typeof tipo === "undefined" || this.useDefaultViewType) {
        this.prodClass = {
          [this.visualizacao]: true,
        };

        return;
      }

      if (typeof tipo === "string") {
        if (tipo.toString().trim() == "") tipo = "bloco-1";
      }

      tipo = tipo.toString().trim();
      switch (tipo) {
        case "bloco-1":
          this.prodClass = {
            "bloco-1": true,
          };
          break;
        case "bloco-2":
          this.prodClass = {
            "bloco-2": true,
          };
          break;
        case "busca-1":
          this.prodClass = {
            "busca-1": true,
          };
          break;
        case "lista-1":
          this.prodClass = {
            "lista-1": true,
          };
          break;
        case "lista-2":
          this.prodClass = {
            "lista-2": true,
          };
          break;
        case "lista-3":
          this.prodClass = {
            "lista-3": true,
          };
          break;
        default:
          break;
      }
    },
    closeOverlay() {
      this.$emit("closeOverlay");
    },
    getQtdMinimaAtivacaoCampanha() {
      const qtdMinimaAtivacao = this.item.qtdMinimaAtivacao - this.quantidade;

      return `Falta ${qtdMinimaAtivacao}${
        qtdMinimaAtivacao == 1 ? " produto" : " produtos"
      }`;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";

ul.list-variante {
  margin: 0;
  display: flex;
  overflow: auto;
  padding: 0;
  margin-top: -10px;
  margin-bottom: 5px;
  align-self: center;

  a {
    border-radius: 50%;
    margin: 2px;
    width: 60px;
    height: 60px;
    opacity: 0.95;

    border: 2px solid gainsboro;
    padding: 2px;
    display: flex;
    justify-content: center;
    flex: none;

    &:hover {
      border-color: black;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      padding: 5px;
      display: flex;
      align-self: center;
    }

    li {
      list-style: none;
      display: flex;

      img {
        max-height: 100%;
        max-width: 100%;
        padding: 5px;
        display: flex;
        align-self: center;
      }
    }
  }
}

.btn-addItem {
  padding: 8.5px 12px;
  font-size: 12px;
  background: var(--brand-primary, $brand-primary);
  width: 100%;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 15px;
}

.img-qr {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.5;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }
}

.lista-1 {
  // variantes
  .product-variant--preview {
    .slick-track {
      gap: 2px;
    }

    .slick-list {
      margin: 0 23.2px;
    }

    .slick-prev,
    .slick-next {
      margin: 0 -0.5px;
    }
  }

  .product-variant-button {
    min-width: 35px;
    padding: 2px;

    .product-variant-button-img-wrapper {
      height: 35px;
    }

    img {
      max-width: 32px;
      max-height: 32px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.sponsored-label {
  background: #e6e6e6;
  color: #3e3e3e;
  padding: 0px 5px;
  border-radius: 5px;
  margin-bottom: 2px;
  align-self: flex-start;

  &__text {
    font-size: 13px;
  }
}
.imagem {
  position: relative;
}

a:hover {
  color: unset !important;
}

.footer-item {
  .fi-info {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    color: #696969;

    small {
      font-size: 11px;
    }
  }
}

.embalagem {
  font-size: 11px;
  color: #696969;
}
.qtd-span {
  display: flex;

  .button-plus {
    border-radius: 0px 5px 5px 0px;
  }

  .button-minus {
    border-radius: 5px 0px 0px 5px;
  }

  .input-qtd {
    color: #777 !important;
    width: auto !important;
    max-width: 45px;
    border-radius: 0px;
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 2px solid #eee;
    padding: 2px 2px;
    height: 30px;
    text-align: center;
    font-weight: bold;

    &:focus {
      border-color: var(--brand-primary, $brand-primary);
      border-width: 1px;
    }
  }

  button {
    border: 0;
    background: #eeeeee;
    margin: 0px 0px;
    font-size: 12px;

    i {
      color: $gray-light;
    }
  }
}

.produto-content {
  height: 100%;
  .titulo-produto {
    // min-height: 55px;
    padding-bottom: 3px !important;
  }

  &__image {
    overflow: hidden;
  }

  .item-tag-info {
    display: flex;
    background: var(--brand-accent, $brand-accent);
    font-size: 13.5px;
    padding: 3px;
    align-items: center;
    color: var(--brand-primary, $brand-primary);
    position: absolute;
    left: 5px;
    height: 20px;

    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;

    span {
      display: none;
    }

    .fa {
      padding: 0 4px;
    }
  }

  &:hover {
    .item-tag-info span {
      display: block !important;
    }
  }

  .discountBadge {
    background: var(--brand-accent, $brand-accent);
    position: absolute;
    // top: 5px;
    right: 5px;
    border-radius: 5px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.9;
    color: var(--brand-primary, $brand-primary);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    z-index: 100;
  }
}

.btn-buy {
  width: 100%;
  padding: 8.5px 16px;
  font-size: 12px;
  background: var(--brand-primary, $brand-primary);
  width: 100%;
  color: white;

  font-weight: bold;
  border: none;
  border-radius: 15px;

  &:hover {
    background: darken($brand-primary, 10%);
  }
}

.bloco-1 {
  .produto-content {
    // min-height: 390px;
    padding: 12px;
    .imgContainerProduto {
      height: 215px;
      position: relative;
    }
    .informacoes {
      display: flex;
      flex-grow: 1;
      .container-footer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .specsProduto {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5px 0;
      }
      .titulo-produto {
        display: flex;
        align-items: center;

        .casadinha {
          padding-left: 5px;
          a {
            font-size: 22px;
            cursor: pointer;
            color: #ff9800;

            &:hover {
              color: #ffbf00;
            }
          }
        }
      }
      .produto-price-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 1px 1px 0;
        .price-item {
          &:before {
            right: 50px;
          }

          display: flex;
          flex-direction: column;

          small {
            font-size: 12px;
            font-weight: 600;
            color: #555555;
            margin-right: 3px;
          }

          .price-value,
          .price-old,
          .price-st {
            font-size: 12px;
            color: #555555;
          }

          .price-default {
            display: flex;
            align-items: center;
          }

          .price-old {
            height: 17px;
          }

          span.price-value {
            font-size: 22px;
            font-weight: 700;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
            color: rgb(51, 51, 51);
          }
        }
      }
    }

    .footer-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      .qtd-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .qtd-subContainer {
          width: 100%;
          // display: flex;
          // justify-content: center;
        }
      }
    }
  }
}

.bloco-2 {
  width: 20% !important; //versão jp - 16.6%
  float: left;

  .produto-content {
    // min-height: 360px;
    padding: 10px;
    .imgContainerProduto {
      height: 200px;
    }
    .informacoes {
      .specsProduto {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        .container-price {
          padding: 0;
        }
      }
      .titulo-produto {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // font-size: 12px;

        .casadinha {
          padding-left: 5px;
          a {
            font-size: 18px;
            cursor: pointer;
            color: #ff9800;

            &:hover {
              color: #ffbf00;
            }
          }
        }
      }

      .produto-price-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-item {
          &:before {
            right: 50px;
          }

          display: flex;
          flex-direction: column;

          padding: 1px 1px 1px 0;

          .price-value,
          .price-old,
          .price-st {
            font-size: 12px;
            color: #555555;
          }

          .price-default {
            display: flex;
            align-items: center;
          }

          .price-old {
            height: 17px;
          }
          span.price-value {
            font-size: 22px;
            font-weight: 700;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
            color: rgb(51, 51, 51);
          }
        }
      }
    }

    .footer-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      flex-wrap: wrap;

      // .btn-link {
      //   font-size: 14px !important;
      // }

      .qtd-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.busca-1 {
  width: 33%;
  float: left;
  min-height: 50px;

  .produto-content {
    min-height: 190px;
    // max-height: 190px;

    .informacoes {
      .titulo-produto {
        display: block !important;
        // font-size: 12px;

        .casadinha {
          a {
            font-size: 25px;
            cursor: pointer;
            color: #ff9800;

            &:hover {
              color: #ffbf00;
            }
          }
        }
      }

      .produto-price-container {
        display: none;
      }
    }

    .footer-item {
      .btn-link {
        display: none;
      }

      .qtd-container {
        display: none !important;
      }
    }
  }

  .imagem {
    float: left;
    width: 100% !important;
    height: 100px !important;

    img {
      max-width: 100% !important;
      max-height: 100px !important;
    }
  }

  .informacoes {
    padding: 9px 9px 9px 9px !important;
    float: right !important;
  }
}

.lista-1 {
  flex: 1 0 33.3333%;

  .produto-content {
    padding: 10px;
    // gap: 10px;
    flex-direction: row !important;
    .imagem {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px;
    }
    a {
      flex: 1 0 20%;
      display: flex;
      justify-content: center;
    }

    &__image {
      display: flex;
      flex-direction: column;
      width: 190px;
    }

    .informacoes {
      justify-content: space-between;
      margin-left: 10px;

      .specsProduto {
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 4px;
        .container-price {
          padding: 0;
        }
      }
      .titulo-produto {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // font-size: 12px;
        align-self: flex-start;

        .casadinha {
          padding-left: 5px;
          a {
            font-size: 22px;
            cursor: pointer;
            color: #ff9800;

            &:hover {
              color: #ffbf00;
            }
          }
        }
      }

      .produto-price-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .price-item {
          &:before {
            right: 45px;
          }
          display: flex;
          flex-direction: column;

          padding: 1px 1px 1px 0;

          small {
            font-size: 12px;
            font-weight: 600;
            color: #555555;
            margin-right: 3px;
          }

          .price-value,
          .price-old,
          .price-st {
            font-size: 12px;
            color: #555555;
          }

          .price-default {
            display: flex;
            align-items: center;
          }

          .price-old {
            height: 17px;
          }
          span.price-value {
            font-size: 22px;
            font-weight: 700;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
            color: rgb(51, 51, 51);
          }
        }
      }
    }

    .footer-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: column;

      // .btn-link {
      //   font-size: 14px !important;
      // }

      .qtd-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 5px;
      }
    }
  }

  .imagem {
    height: 100% !important;

    img {
      // padding: 0px; //margin-left: 20px;
      max-height: 120px !important;
    }
  }
  // .imagem {
  //   float: left;
  //   width: 33% !important;
  //   height: 130px !important;

  //   img {
  //     padding: 0px; //margin-left: 20px;
  //     max-height: 120px !important;
  //   }
  // }

  .informacoes {
    float: right;
    width: 65%;
  }
}
.promocao:hover {
  background-color: #038f4723;
}
.promocao {
  padding: 6px;
  transition: all 0.3s;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 6px;

  a {
    font-size: 22px;
    cursor: pointer;
    color: #028d45;

    &:hover {
      color: #018a43;
    }
  }
  .icon-container {
    width: 45px;
  }
  p {
    margin: 0;
  }
  .text-promotion {
    color: #007538;

    line-height: 1.5;
    font-size: 10px;
    font-weight: bold;
  }
}

.lista-2 {
  width: 33%;
  float: left;
  padding: 5px !important;

  .produto-content {
    min-height: 135px;

    .informacoes {
      .titulo-produto {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // font-size: 12px;

        .casadinha {
          padding-left: 5px;
          a {
            font-size: 22px;
            cursor: pointer;
            color: #ff9800;

            &:hover {
              color: #ffbf00;
            }
          }
        }
      }

      .produto-price-container {
        display: flex;
        flex-direction: column;

        .price-item {
          &:before {
            right: 50px;
          }

          display: flex;
          flex-direction: column;

          padding: 1px 1px 1px 0;

          small {
            font-size: 12px;
            font-weight: 600;
            color: #555555;
            margin-right: 3px;
          }

          .price-value,
          .price-old,
          .price-st {
            font-size: 12px;
            color: #555555;
          }

          .price-default {
            display: flex;
            align-items: center;
          }

          .price-old {
            height: 17px;
          }
          span.price-value {
            font-size: 22px;
            font-weight: 700;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
            color: rgb(51, 51, 51);
          }
        }
      }
    }

    .footer-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      flex-wrap: wrap;
      // .btn-link {
      //   font-size: 14px !important;
      // }

      .qtd-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .imagem {
    display: none !important;
  }

  .img-pop {
    width: 100px;
  }

  .img-thumb {
    display: block !important;
    width: 10% !important;
    float: left;
    padding-top: 8px;

    i {
      color: var(--brand-primary, $brand-primary) !important;
    }
  }

  .informacoes {
    float: right;
    width: 100%;
    padding: 0px 0px 0px 0px !important;
    width: 90%;
  }

  .titulo {
    span {
      min-height: 30px !important;
      max-height: 32px !important;
    }
  }
}

.lista-3 {
  width: 100%;
  float: left;

  .produto-content {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 15px !important;
    padding-right: 5px !important;
  }

  .img-thumb {
    display: none !important;
  }

  .imagem {
    float: left;
    width: 25% !important;
    display: block !important;

    img {
      padding: 3px !important; //margin-left: 20px;
    }
  }

  .informacoes {
    padding: 15px 6px 6px 6px !important;
    float: right !important;
    width: 70% !important;
  }

  .titulo {
    span {
      min-height: 30px !important;
      max-height: 32px !important;
    }
  }
}

@media (max-width: 1295px) {
  .bloco-2 {
    width: 20% !important;

    .imagem {
      img {
        padding: 2px !important;
        max-height: 170px !important;
      }
    }
  }
}

// Versão desktop min

@media (min-width: $screen-lg-min) {
  .bloco-1 {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 25%;
    width: 100%;
    flex: 0 1 25%;
  }
}

// Versão tablets maiores / pcs com tela pequena
@media (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
  .bloco-1 {
    // margin: 0 1px -1px -1px;
    // border: 1px solid #e5e5e5;
    // border-top: 0;
    // border-right: 0;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 33.3333%;
    width: 100%;
    flex: 0 1 33.3333%;
  }
}

// Versão tablets maiores / pcs com tela pequena
@media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
  .bloco-1 {
    max-width: 50%;
    flex: 0 1 50%;
  }
}

// Versão mobile / tablets.
@media (max-width: $screen-sm-min) {
  .bloco-1 {
    // margin: 0 1px -1px -1px;
    // border: 1px solid #e5e5e5;
    border-top: 0;
    border-right: 0;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 50%;
    width: 100%;
    flex: 0 1 50%;

    .imagem {
      img {
        padding: 2px !important;
      }
    }

    .produto-content {
      border-top: 0 !important;
      border-right: 0 !important;
      margin-right: 1px;
    }
  }

  .bloco-2 {
    width: 25% !important; //versão jp - 16.6%
    float: left;

    .imagem {
      img {
        padding: 2px !important;
        max-height: 170px !important;
      }
    }

    .produto-content {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  .lista-1 {
    flex: 1 0 50%;

    .produto-content {
      align-items: center;
    }
    .imagem {
      // float: left;
      // width: 33% !important;
      height: 100% !important;
      img {
        // padding: 0px; //margin-left: 20px;
        padding: 5px;
        max-height: 120px;
      }
    }

    .informacoes {
      float: right;
      width: 65%;
    }
  }

  .lista-2 {
    width: 100%;
    float: left;
    padding: 5px !important;

    .produto-content {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      padding-left: 15px !important;
      padding-right: 10px !important;
    }

    .imagem {
      display: none;
    }

    .img-pop {
      width: 100px;
    }

    .img-thumb {
      display: block !important;
      width: 10% !important;
      float: left;
      padding-top: 8px;
    }

    .informacoes {
      float: right;
      width: 100%;
      padding: 0px 0px 0px 0px !important;
      width: 90%;
    }

    // .titulo {
    //   span {
    //     min-height: 30px !important;
    //     max-height: 32px !important;
    //   }
    // }
  }
}

.lista-3 {
  width: 100%;
  float: left;
  padding: 5px !important;

  .produto-content {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 15px !important;
    padding-right: 10px !important;
  }

  .imagem {
    display: none !important;
  }

  .img-pop {
    width: 100px !important;
  }

  .img-thumb {
    display: none !important;
  }

  .informacoes {
    float: left !important;
    width: 100%;
    padding: 0px 0px 0px 0px !important;
    width: 90%;
  }

  .titulo {
    span {
      min-height: 30px !important;
      max-height: 32px !important;
    }
  }
}

@media (max-width: 550px) {
  .bloco-1 {
    width: 50%;

    .imagem {
      img {
        padding: 2px !important;
      }
    }
  }

  .bloco-2 {
    width: 33.3% !important;

    .imagem {
      img {
        padding: 2px !important;
        max-height: 170px;
      }
    }
  }

  .lista-1 {
    flex: 1 0 100%;
    .qtd-subContainer {
      width: 50%;
    }
  }

  .busca-1 {
    width: 33.3%;

    .imagem {
      img {
        padding: 2px !important;
      }
    }
  }
}

@media (max-width: 430px) {
  .bloco-1 {
    width: 100%;
    .imagem {
      img {
        padding: 2px !important;
      }
    }
    .informacoes {
      .container-footer {
        .produto-price-container {
          flex-direction: column;
          align-items: flex-start !important;
        }
      }
    }
  }

  .bloco-2 {
    width: 50% !important;

    .imagem {
      img {
        padding: 2px !important;
        max-height: 170px !important;
      }
    }
  }

  .busca-1 {
    width: 100%;
    float: left;

    .produto-content {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 15px !important;
      padding-right: 5px !important;
      min-height: 100px;
    }

    .imagem {
      float: left;
      width: 33% !important;
      height: 100px !important;

      img {
        padding: 0px; //margin-left: 20px;
        max-height: 100px !important;
      }
    }

    .informacoes {
      float: right;
      width: 65%;
    }
  }

  .lista-1 {
    flex: 1 0 100%;
    .qtd-subContainer {
      width: initial;
    }
  }
}

.item-produto {
  padding: 5px;
  box-sizing: border-box;
  width: 100%;

  .informacoes {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .img-thumb {
    display: none;
  }

  .produto-content {
    box-shadow: 0 0 10px -5px #0000003b;
    background: white;
    transition: 0.2s;

    display: flex;
    flex-direction: column;
  }
  .produto-content:hover {
    box-shadow: 0 0 10px -3px #0000003b;
  }

  .titulo {
    font-size: 15px;
    font-weight: 500;
    color: #303932 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }

  .descricao {
    font: 13px/16px "Montserrat", sans-serif;
    padding: 5px 0px 10px 0px;
    display: none;
  }
  .imgContainerProduto {
    // height: 200px;
    width: 100%;
  }
  .containerImg150 {
    height: 150px;
  }
  .imagem {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    max-height: 205px;
  }
  .img {
    max-height: 80%;
    max-width: 85%;
    display: initial;
  }

  .codigo {
    font-size: 11px;
    text-align: left;
    // margin-top: 5px;
    font-weight: 400;

    > label {
      margin: 0px;
      font-weight: unset;
      font-size: 11px;
    }

    > span {
      font-weight: 600;
    }
  }
  .headInfoContent {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
}

#item-produto-2 {
  margin: 0px;
  padding: 5px;

  .informacoes {
    background-color: var(--brand-primary, $brand-primary);
    padding: 6px 6px 26px 6px;

    span {
      color: white;
    }
  }

  .produto-content {
    padding: 0px 0px 0px 0px;
    border: 1px solid #eee;
    // border-radius: 4px;
    background-color: #fff;
  }

  .produto-content:hover {
    box-shadow: 0px 0px 45px #e6e6e6;
  }

  .titulo {
    padding: 0px 0px 0px 0px;
    font-size: 12px;
    > span {
      font-weight: 600;
      line-height: 14px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      min-height: 28px;
      max-height: 32px;
    }
  }

  .descricao {
    font: 13px/16px "Montserrat", sans-serif;
    padding: 5px 0px 10px 0px;
    display: none;
  }

  .imagem {
    height: 100%;
  }

  .img {
    max-height: 100%;
    padding: 5px;
    display: initial;
  }

  .codigo {
    font-size: 12px;
    text-align: end;
    bottom: 0;
    right: 0;
    position: absolute;
    padding: 5px 5px 3px 5px;

    > label {
      margin: 0px;
      font-weight: unset;
      color: $brand-info;
    }

    > span {
      font-weight: 600;
    }
  }
}

// .codigo {
//   white-space: nowrap;
// }

.container-price {
  .price-item {
    position: absolute;
    background: #fff;
    border-radius: 4px;
    display: none !important;
    padding: 15px !important;
    width: 100%;
    max-width: 300px;
    margin-top: 15px;
    z-index: 999;
    box-shadow: 0px 0px 15px #0000004a;

    hr {
      margin-top: 5px;
      margin-bottom: 5px;
      border: 0;
      width: 100%;
      border-top: 1px solid #ccc;
      z-index: 999999999999999999;
    }

    small {
      font-weight: bold;
    }

    small,
    span {
      font-size: 15px !important;
    }
  }

  #info-price {
    cursor: pointer;
    color: #999;
    padding: 0 0 0px 5px;

    &:hover + .price-item {
      display: flex !important;
    }
  }
}

// @media (max-width: 1365px) {
//   .titulo span {
//     font-size: 11px !important;
//   }
// }

@media (max-width: 1295px) {
  // .titulo span {
  //   font-size: 10px !important;
  // }

  .bloco-2 {
    .container-price {
      .price-item {
        small,
        span {
          font-size: 11px !important;
        }
        small {
          font-weight: bold;
        }
      }
    }
  }

  // .codigo {
  //   font-size: 8px !important;
  // }
}

@media (max-width: 991px) {
  .container-price {
    .price-item {
      small,
      span {
        font-size: 12px !important;
      }
    }
  }

  .bloco-2 {
    .container-price {
      .price-item {
        small,
        span {
          font-size: 10px !important;
        }
        small {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  // .titulo span {
  //   font-weight: 600 !important;
  //   font-size: 9px !important;
  // }

  // i {
  //   font-size: 18px;
  // }
}

@media (max-width: 549px) {
  .container-price {
    .price-item {
      small,
      span {
        font-size: 15px !important;
      }
    }
  }
}

.slick-track {
  .container-price {
    .price-item {
      margin-top: -150px;
    }
  }
}
@media (max-width: 548px) {
  .item-produto {
    box-sizing: border-box;
    padding: 3px;
    .produto-content {
      min-height: unset !important;
      padding: 10px;
      .imgContainerProduto {
        height: 130px;
      }
    }
    .imagem {
      margin-bottom: unset;
    }
  }
  .qtd-container {
    width: 100%;
    margin-top: 10px;
    .qtd-subContainer {
      // width: 50%;
    }
  }

  .btn-buy {
    font-size: 12px;
    width: 100%;
  }
  .specsProduto {
    align-items: flex-start !important;
    flex-direction: column-reverse;
  }

  .qtd-subContainer {
    width: 100%;
    padding-top: 5px;
  }
}
</style>
